import './App.css';
import React from 'react';
import { gapi } from 'gapi-script';
import global from './Global';
const _app = new global();
class App extends React.Component{

  constructor(props){
    super(props);
    
    this.state = {
      tokenClient: false,
      gapiInited: false,
      gisInited: false,
      driveId: 'root',
      folderId: 'root',
      responseToken: null,
      user: null,
      loaddrives: false,
      loadfiles: false,
      drives: [],
      files: [],
      uprogress: [],
      breadcrumb: [],
      notifications: [],
      showMenu: false,
      cdata: {},
      contextMenu: []
    }
    this.uprogress = [];
    
    this.notifications = [];
    this.breadcrumb = [];
  }
 async componentDidMount(){
    
    _app.gapi.script.onload = this.handleClientLoad;
    document.body.appendChild(_app.gapi.script);
    document.body.appendChild(_app.gapi.accountscript);
    const me = this;
    setTimeout(async()=>{
      await me.changedrive(me.state.driveId);
    }, 2000);
  }

  hideMenu = (e) => { 
      _app.log(e);
      document.getElementById("contextMenu").style.display = "none";
     // for(const flink of e.target.querySelectorAll('.fileLink')){
     //   flink.parentNode.classList.remove('focused');
    //  }
      
  } 

  rightClick = (e) => { 
      e.preventDefault(); 
      let json;
      let type;
      
      try{
        json = e.target.getAttribute('data-json');
        json = JSON.parse(json);
        type = json.mimeType;
        this.setState({
          cdata: json
        });
      }catch(ex){
      }
      _app.log(json);
      this.contextItems = [];
      //e.target.parentNode.parentNode.parentNode.classList.add('focused');
      if (document.getElementById("contextMenu").style.display === "block"){ 
          this.hideMenu(e); 
      }else{ 
        _app.log(type === "application/vnd.google-apps.folder");
          if(type === "application/vnd.google-apps.folder"){
            this.contextItems.push(
              <li className="share d-none"><a href="#"><i className="fa fa-share" aria-hidden="true"></i> Share</a></li> 
            );

            this.contextItems.push(
              <li className="share d-none"><a href="#"><i className="fa-light fa-folder-open"></i> Open Folder</a></li> 
            );
            
            this.contextItems.push(
              <li className="review"><a href={json !== null ? json.webViewLink : '#'} target="_blank" ><i className="fa-solid fa-info"></i> View on Google Drive</a></li>
            );
            
            this.contextItems.push(
              <li className="trash" onClick={this.deleteFile}><a href="#"><i className="fa fa-trash" aria-hidden="true"></i> Delete</a></li> 
            );
            
            
          }else if(json === null){
            this.contextItems.push(
              <li className="new" onClick={(e)=>{
                e.preventDefault();
                document.getElementById('files').click();
              }}><a href="#"><i className="fa-solid fa-cloud-arrow-up"></i> Upload</a></li> 
            );
          }else{
            this.contextItems.push(
              <li className="share d-none"><a href="#"><i className="fa fa-share" aria-hidden="true"></i> Share</a></li> 
            );
            
            this.contextItems.push(
              <li className="share d-none"><a href="#"><i className="fa-solid fa-eye"></i> Open File</a></li> 
            );

            this.contextItems.push(
              <li className="review"><a href={json !== null ? json.webViewLink : '#'} target="_blank"><i className="fa-solid fa-info"></i> View Detail</a></li>
            );
            this.contextItems.push(
              <li className="download"><a href={json !== null ? json.webViewLink : '#'}><i className="fa fa-download" aria-hidden="true"></i> Download</a></li> 
            );
            this.contextItems.push(
              <li className="trash" onClick={this.deleteFile}><a href="#"><i className="fa fa-trash" aria-hidden="true"></i> Delete</a></li> 
            );
          }

          _app.log(this.contextItems);
          this.contextItems.push(
            <li className="new" onClick={this.createFolder}><a href="#"><i className="fa-solid fa-folder-plus"></i> New Folder</a></li> 
          );
          
          var menu = document.getElementById("contextMenu")      
          menu.style.display = 'block'; 
          menu.style.left = e.pageX + "px"; 
          menu.style.top = e.pageY + "px"; 
          this.setState({
            contextMenu: this.contextItems
          })
      } 
  }

  handleClientLoad = async ()=>{
    gapi.load('client:auth2', this.initClient);
    
  }

  handleLogin = async(e = false) => {
    gapi.auth2.getAuthInstance().signIn();
  }



  handleSignoutClick =  (e) => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      let cnf = window.confirm('Are you sure to sign out?');
      if(cnf){
        window.google.accounts.oauth2.revoke(token.access_token);
        window.gapi.client.setToken('');
       // gapi.auth2.getAuthInstance().signOut();
        
        this.setState({
          responseToken: '',
          gapiInited: false,
          gisInited: false
        });
        setTimeout(()=>{
          gapi.auth2.getAuthInstance().signOut();
          window.location.reload();
        }, 1000)
        return false;
      }
      
    }
    
  }

  listdrives = async (searchTerm = null) => {
    this.setState({
      loaddrives: true
    })
    let resp = await window.gapi.client.drive.drives.list({});
    if(resp.result !== undefined && resp.result.drives !== undefined){
      
      let drives = [{
        id: "shared",
        name: "Shared with Me",
        kind: "drive#drive",
        q: "sharedWithMe = true"
      }];
      drives = drives.concat(resp.result.drives);
      
      let cdrive = window.localStorage.getItem('cdrive');
      if(drives.length > 0 && cdrive === null){
        window.localStorage.setItem('cdrive', drives[0].id);
        cdrive = drives[0].id;
      }
      
        //_app.log(cdrive);
      
      _app.log(drives);
      this.setState({
        loaddrives: false,
        drives: drives,
        driveId: cdrive
      });
      
      _app.log(resp.result.drives);
    }else{
      this.setState({
        loaddrives: false,
        drives: []
      })
    }
    
  };

  Authorize = async() => {
    let gu = window.gapi.auth2.getAuthInstance();
    _app.log(gu);
    return window.gapi.auth2.getAuthInstance()
        .signIn({scope: _app.gapi.scopes})
        .then(function() { _app.log("Sign-in successful"); },
              function(err) { console.error("Error signing in", err); });
    
    
    
  }

  updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      // Set the signed in user
      let user = gapi.auth2.getAuthInstance().currentUser;
      //_app.log(gapi.auth2.getAuthInstance().currentUser);
      this.setState({
        user: user,
        gapiInited: true
      })
      this.listdrives();
      // list files if user is authenticated
      //listFiles();
    } 
  };

  initClient = async () => {
    try{
      this.setState({
        gisInited: true
      });
      
      gapi.client.init({
        apiKey: _app.gapi.apiKey,
        discoveryDocs: [_app.gapi.discoveryUrl],
        client_id: _app.gapi.clientId,
        scope: _app.gapi.scopes,
      }).then(()=>{
        gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
        _app.log(gapi.auth2.getAuthInstance().isSignedIn.get());
        if(gapi.auth2.getAuthInstance().isSignedIn.get()){
          this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        }
       
      }).catch((err)=>{
        _app.log(err);
      })
      
      
         /// this.state.googleAuth.isSignedIn.listen(this.updateSigninStatus);  

           //   _app.log(this.state.googleAuth);
       //  document.getElementById('signout-btn').addEventListener('click', this.signOutFunction);

      
    }catch(e){
      _app.log(e);
    }
  }

  

  listFiles = async (drive = false, searchTerm = "'root' in parents", trash = false) => {
    this.setState({
      loadfiles: true
    });
    
    searchTerm = drive === 'shared' ? "sharedWithMe=true" : searchTerm;
    drive = drive === 'shared' ? 'root' : drive;
    _app.log(searchTerm);
    console.log(searchTerm);
    searchTerm = drive ? searchTerm.replace("'root'", `'${drive}'`) : searchTerm;
    searchTerm += ` and trashed=${trash}`;

    _app.log(searchTerm);
    let params = {
      "includeTeamDriveItems": true,
      "orderBy": "folder,modifiedTime desc,name",
      "supportsAllDrives": true,
      "supportsTeamDrives": true,
      pageSize: 100,
      fields: 'nextPageToken, files(id, driveId, name, mimeType, modifiedTime, size, version, webContentLink, webViewLink, iconLink, thumbnailLink, createdTime, sharingUser)',
      q: searchTerm,
    }
    let resp = await gapi.client.drive.files
      .list(params);
      this.setState({
        loadfiles: false
      });
      if(resp.result !== undefined && resp.result.files !== undefined){
        this.setState({
          files: resp.result.files
        })
      }
    _app.log(resp);
  };

  changedrive = async(e, attr = {}) => {
    
    try{
      if(typeof(e) === 'object' && e.target !== undefined){
        e.preventDefault();
        await this.listFiles(e.target.getAttribute('id'));
        this.breadcrumb = [];
        this.breadcrumb[0] = {name: e.target.innerText, id: e.target.getAttribute('id'), type: 'drive'};
        _app.log(this.breadcrumb);
        this.setState({
          driveId: e.target.getAttribute('id'),
          folderId: e.target.getAttribute('id'),
          breadcrumb: this.breadcrumb
        })
      }else{
        await this.listFiles(e);
        if(Object.keys(attr).length < 1){
         attr = this.state.drives.filter((x)=>{
             return x.id === e;
          });
          if(Array.isArray(attr) && attr.length > 0){
            attr = attr[0]
          }
        }
        this.breadcrumb = [];
        this.breadcrumb[0] = {name: attr.name, id: e, type: attr.type};
        this.setState({
          driveId: e,
          folderId: e,
          breadcrumb: this.breadcrumb
        })
      }
    }catch(err){
      _app.log(err);
    }
    
    //
  }

  changeLocation = async (e, attr = {}) => {
    
    let parms, json, driveId;
    if(typeof(e) === 'object' && e.target !== undefined){
      e.preventDefault();
      _app.log(this.state.driveId);
      
      try{
        json = e.target.getAttribute('data-json');
        json = JSON.parse(json);
      }catch(ex){

      }
      driveId = json !== undefined && json.driveId !== undefined ? json.driveId : this.state.driveId;
      parms = `('${e.target.getAttribute('data-id')}' in parents)`;
      console.log(json);
      
      await this.listFiles(driveId, parms);
      this.breadcrumb.push({name: e.target.getAttribute('data-name'), id: e.target.getAttribute('data-id'), type: 'folder'});
      
      this.setState({
        folderId: e.target.getAttribute('data-id'),
        breadcrumb: this.breadcrumb
      })
    }else{
      await this.listFiles(this.state.driveId, `'${e}' in parents`);
      
      this.breadcrumb.push({name: attr.name, id: e, type: attr.type});
      this.breadcrumb = this.breadcrumb.filter((x, i) => i + (parseInt(attr.idx) + 1) < this.breadcrumb.length);
      _app.log(this.breadcrumb);
      this.setState({
        folderId: e,
        breadcrumb: this.breadcrumb
      })
    }
  }

  createFolder = async (e) => {
    let name = window.prompt('Give a name to the new folder');
      const fileMetadata = {
        name: name,
        type: 'application/vnd.google-apps.folder'
      };
      this.uploadFile(fileMetadata);
  }
  

  uploadFile = async(file, index, fileSize) => {
    
    var metadata = {
      'name': file.name, // Filename at Google Drive
      'mimeType': file.type, // mimeType at Google Drive
      'supportsAllDrives': true,
      // TODO [Optional]: Set the below credentials
      // Note: remove this parameter, if no target is needed
      'parents': [this.state.folderId], // Folder ID at Google Drive which is optional
    };
    
    var accessToken = gapi.auth.getToken().access_token; // Here gapi is used for retrieving the access token.
    var form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    var xhr = new XMLHttpRequest();
    xhr.open('post', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id&supportsAllDrives=true');
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.responseType = 'json';
    xhr.upload.onprogress = (evt) => {
      if (evt.lengthComputable) 
      {  // evt.loaded the bytes the browser received
         // evt.total the total bytes set by the header
         // jQuery UI progress bar to show the progress on screen
         
        
        this.uprogress[index] = {name: file.name, size: fileSize, up: evt.loaded, total: evt.total, percent: Math.round((evt.loaded / evt.total) * 100) + '%'};
        
        _app.log(this.uprogress);
        this.setState({
          uprogress: this.uprogress
        });
      } 
    }

    xhr.onload = () => {
      
      if(xhr.response.id === undefined){
        this.notifications.push({
          type: 'failed',
          class: 'list-group-item list-group-item-danger',
          msg: <>File Upload Failed. <b>{JSON.stringify(xhr.response)}</b></>
        })
      }else{
        this.notifications.push({
          type: 'success',
          class: 'list-group-item mb-1 list-group-item-success',
          msg: <>File uploaded successfully. The File name <b><a data-fancybox="true" data-caption={file.name} data-type="iframe" href={`https://drive.google.com/uc?id=${xhr.response.id}&amp;export=view`}>{file.name}</a> and id is {xhr.response.id}</b></>
        });
      }
      this.setState({
        notifications: this.notifications
      })
      
    };

    xhr.onloadend = async () => {
      await this.driveRefresh();
    }
    xhr.send(form);
  }

  convertByte = (size) => {
    if (size > (1024 * 1024 * 1024)){
      return `${(size/(1024 * 1024 * 1024)).toFixed(2)} Gb`;
    }else if (size > (1024 * 1024)){
      return `${(size/(1024 * 1024)).toFixed(2)} Mb`;
    }else if(size > 1024){
      return `${(size/1024).toFixed(2)} Kb`;
    }else{
      return `${size} Bytes`;
    }
  }

  fileUpload = async (e) => {
    _app.log(e.target.files);
    let files = e.target.files;
    //document.querySelector('#result .notification').innerHTML = '';
    let fileSize, allowed;
    
    for(let i = 0 ; i < files.length; i ++){
      fileSize = files[i].size; 
           
      _app.log(fileSize);
      if(fileSize <= _app.maxFileSize){
        await this.uploadFile(files[i], i, fileSize);
        this.driveRefresh();
      }else{
        this.notifications.push({
          type: 'failed',
          class: 'list-group-item list-group-item-danger',
          msg: <>Max {this.convertByte(_app.maxFileSize)} file(s) allowed. File <strong>{files[i].name}</strong> size is {this.convertByte(files[i].size)}</>
        });
        this.setState({
          notifications: this.notifications
        })
      }
      
    }
  }

  deleteFile = async (e) => {
    const cm = this;
    if(typeof(this.state.cdata) === 'object' && Object.keys(this.state.cdata).length > 0){
      if(window.confirm(`Are You Sure to Delete ${this.state.cdata.name} file?`)){
        
          var request = gapi.client.drive.files.update({
            'fileId': this.state.cdata.id,
            'supportsAllDrives': true,
            "trashed": true
          });
          request.execute(function(resp) {
            _app.log(resp);
            if(typeof(resp) === 'object' && resp.code !== undefined){
              cm.notifications.push({
                type: 'failed',
                class: 'list-group-item list-group-item-danger',
                msg: <>{resp.code === 404 ? resp.message + ' or You don\'t have permission!': resp.message}</>
              });
              cm.setState({
                notifications: cm.notifications
              })
            }else if(typeof(resp) === 'object' && resp.result !== undefined){
              cm.notifications.push({
                type: 'failed',
                class: 'list-group-item list-group-item-warning',
                msg: <>{cm.state.cdata.mimeType === 'application/vnd.google-apps.folder' ? 'Folder' : 'File'} <strong>"{cm.state.cdata.name}"</strong> was moved to trash!</>
              });
              cm.setState({
                notifications: cm.notifications
              });
              cm.driveRefresh();
            }else{
              cm.notifications.push({
                type: 'failed',
                class: 'list-group-item list-group-item-warning',
                msg: <>{cm.state.cdata.mimeType === 'application/vnd.google-apps.folder' ? 'Folder' : 'File'} <strong>"{cm.state.cdata.name}"</strong> was deleted!</>
              });
              cm.setState({
                notifications: cm.notifications
              });
              cm.driveRefresh();
            }
            
            
          });
          
        
        
      }
    }else{
      alert('No Item Selected!')
    }
   
  }

  driveRefresh = async () => {
    _app.log(this.state)
    await this.listFiles(this.state.driveId, `('${this.state.folderId}' in parents) and trashed=false`);
    
  }

  moveto = async (e) => {
    let data = e.target.getAttribute('data');
    document.querySelector('.breadcrumb-item').classList.remove('active');
    e.target.parentNode.classList.add('active');
    data = JSON.parse(data);
    _app.log(data);
    data['idx'] =  e.target.getAttribute('id');
    if(data.type === 'drive'){
      await this.changedrive(data.id, data);
    }else{
      await this.changeLocation(data.id, data);
    }
    //await this.listFiles(this.state.driveId, `('${this.state.folderId}' in parents)`);
  }

  closeNotification = (e) => {
    e.target.parentNode.remove();
  }

  toggleNav = () => {
    this.setState({
      showMenu: this.state.showMenu ? false : true
    })
  }

  render(){
    _app.log(this.state)
    return (
      <div className="App" onClick={this.hideMenu}>
        
        <header className="App-header">
          <div className='row'>
            <div className='col-md-6 col-8'>
              <div className="logo">
                <img src="./logo.png" alt="Sri Sathya Sai Media Centre" style={{maxHeight:'64px'}} />
                <h1 className="d-none d-sm-inline-block">Sri Sathya Sai Workspace</h1>
              </div>
              
            </div>
            <div className='col-md-6 col-4 align-self-center'>
              {
                this.state.gapiInited && this.state.gisInited ? 
                <div className='text-end'>
                  <button onClick={this.toggleNav} className='btn btn-sm btn-primary d-sm-none d-inline-block'>
                    {this.state.showMenu ? <i className="fa-regular fa-circle-xmark"></i> : <i className="fa-solid fa-bars"></i>}
                  </button><span className="d-sm-none d-inline-block">&nbsp;|&nbsp;</span>
                  <button title="Sign Out" onClick={this.handleSignoutClick} className='btn btn-sm btn-dark'>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>:
                <div className='text-end'>
                <button title="Sign In" onClick={this.handleLogin} className='btn btn-sm btn-primary'>
                <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </div>
              }
              
            </div>
          </div>
        </header>
        
        <main>
              <div className='wrapper'>
                {
                  this.state.user === null ?
                  
                  <div className='row'>
                  <div className='col-md-12 text-center align-self-center' style={{maxWidth: '50vh', background:'#fff', borderRadius:'15px', padding:'30px', margin:'10% auto', fontSize:'18px'}}>
                  <img src="https://raw.githubusercontent.com/hlynurstef/bs-google-signin/HEAD/assets/google-signin-button.png" alt='login with Google' style={{maxWidth:'100%', cursor:'pointer'}} onClick={this.handleLogin} />
                      with your<br />
                      <pre>email@<strong>sssmediacentre.org</strong></pre>
                      
                  </div>
                  </div> :
                  <>
                    <div id="result">
                          <ul className="list-group">
                            {this.state.uprogress.map((x,i)=>{
                              return (<li key={x.name} className={ x.percent === '100%' ? "list-group-item mb-1 list-group-item-success" : "list-group-item mb-1 list-group-item-warning" }>
                                {x.name} {x.percent === '100%' ? <i className="fa-solid fa-square-check"></i> : x.percent}
                                <button className='close'  title="Close Notification"  onClick={(e)=>{
                                    delete(this.uprogress[i]);
                                    _app.log(this.uprogress);
                                    this.setState({
                                      uprogress: this.uprogress
                                    })
                                  }}><i className="fa-solid fa-circle-xmark"></i></button></li>)
                            })}
                          </ul>
                          <ul className="notification list-group">
                            {
                              this.state.notifications.map((x, i)=>{
                                return (
                                  <li className={x.class}>{x.msg}<button className='close' title="Close Notification" onClick={(e)=>{
                                    delete(this.notifications[i]);
                                    _app.log(this.notifications);
                                    this.setState({
                                      notifications: this.notifications
                                    })
                                  }}><i className="fa-solid fa-circle-xmark"></i></button></li>
                                )
                              })
                            }
                          </ul>
                      </div>
                    <div className='row'>
                  <div className='col-lg-2 col-md-4 col-sm-3 sidemenu' style={this.state.showMenu ? {display: 'block', position:'fixed', top: '108px', left: '5px', zIndex: '9'} : {}}>
                    
                    
                    <div className='list-group drivemenu'>
                      {
                        this.state.drives.length > 0 ? this.state.drives.map((x)=>{
                          return (<button key={x.id} className='list-group-item list-group-item-action' onClick={this.changedrive} id={x.id}>{x.name}</button>)
                        }) :
                        <div className='list-group-item list-group-item-action'>
                          {
                            this.state.loaddrives ? <>Loading...</> : <>Team Drive not Available.</>
                          }
                        </div>
                        
                      }
                    </div>
                    <hr />
                    <div className='file-Uploader'>
                      <h4>File Uploader</h4>
                      
                      <div>
                        <input onChange={this.fileUpload} type={'file'} id="files" name="files[]" multiple />
                      <div className='boundry'>
                        <div className='dragndrop'>Click<br />&apos;OR&apos;<br />Drop</div>
                      </div>
                      </div>
                      
                      
                      <div className='p-3 text-center text-sm'>
                        (Max {this.convertByte(_app.maxFileSize)} at a time)<br />
                        File will be uploaded to the current drive
                      </div>

                      <div className="text-center pb-3">
                          <div id="progress-wrp">
                          <ul className='list-group' style={{wordBreak:'break-all'}}>
                            {
                              this.state.uprogress.map((x,i)=>{
                                let percent = parseInt(x.percent);
                                return (<li className='list-group-item'>
                                  <h5>{x.name}</h5>
                                  <div className="progress">
                                    <div className={percent === 100 ? "progress-bar progress-bar-striped" : "progress-bar progress-bar-striped progress-bar-animated"} role="progressbar" style={{width: x.percent}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">{x.percent}</div>
                                  </div>
                                </li>)
                              })
                            }
                          </ul>
                          </div>
                      
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-10 col-md-8 col-sm-9 content' >
                    <div className='backclick' onContextMenu={this.rightClick}></div>
                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                      {
                        this.state.breadcrumb.length > 0 ? 
                        this.state.breadcrumb.map((x,i)=>{
                          return (<li className="breadcrumb-item" key={x.id} ><button className="btn btn-sm btn-light" data={JSON.stringify(x)}  onClick={this.moveto} id={i}>{x.name}</button></li>);
                        }) : <></>
                      }
                      </ol>
                    </nav>
                    <div id="contextMenu" className="context-menu" style={{display: 'none'}}> 
                        <ul className="menu">
                          {this.state.contextMenu.map((x)=>{return x;})}
                        </ul> 
                    </div>
                    {
                      this.state.loadfiles ? <>Loading...</> : <></>
                    }
                      <div className='file-grid row' >
                      
                      {
                        this.state.files.length > 0 ?
                        this.state.files.map((x)=>{
                          return (
                            <div className="col-xl-2 col-md-3 col-sm-4 col-6 mb-2">
                              <div key={x.id} title={x.name} className='card h-100' onContextMenu={this.rightClick} data-json={JSON.stringify(x)} data-id={x.id}>
                                <div className="card-header d-none">
                                  {JSON.stringify(x)}
                                </div>
                                <div className='card-body' data-json={JSON.stringify(x)} data-id={x.id}>{_app.FileType(x, this.changeLocation)}</div>
                                <div className="card-footer" data-json={JSON.stringify(x)}>
                                  { (x.mimeType === 'application/vnd.google-apps.folder') ?
                                    <a data-json={JSON.stringify(x)} data-id={x.id} onDoubleClick={this.changeLocation}>
                                      <img src={x.iconLink} alt={x.name} style={{position:'relative', top: '-1px'}} /> <span onBlur={(e)=>{
                                        e.target.removeAttribute('contenteditable');
                                      }} onDoubleClick={(e)=>{
                                        e.target.setAttribute('contenteditable',true);
                                        e.parentNode.style.overflow = 'visible';
                                        e.target.focus();
                                      }}>{x.name}</span>
                                    </a>
                                  :
                                    <a data-fancybox data-caption={x.name} data-type="iframe" href={`https://drive.google.com/file/d/${x.id}/preview`}>
                                      <img src={x.iconLink} alt={x.name} style={{position:'relative', top: '-1px'}} /> <span onBlur={(e)=>{
                                        e.target.removeAttribute('contenteditable');
                                      }} onDoubleClick={(e)=>{
                                        e.target.setAttribute('contenteditable',true);
                                        e.parentNode.style.overflow = 'visible';
                                        e.target.focus();
                                      }}>{x.name}</span>
                                    </a>
                                  }
                                
                                </div>
                              </div>
                            </div>
                          )
                        }) : <div>No Files Available.</div>
                      }
                      </div>
                  </div>
                </div>
                  </>
                  
                }
                
              </div>
        </main>
      </div>
    );
  }
}

export default App;
