import React from 'react';

class global{
    constructor(){
        this.debug = true;
        this.gapi = {};
        this.gapi.src = "https://apis.google.com/js/api.js";
        this.gapi.apiKey = 'AIzaSyB6ObEpNufbTPL7_RJe1tnwTICzioVCX8g';
        this.gapi.clientId = '975272254787-af35hjfi6jo0g29a7ie98fj4571u6fcj.apps.googleusercontent.com';
        this.gapi.scopes = "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.readonly";
        this.gapi.discoveryUrl = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
        this.gapi.script = document.createElement('script');
        this.gapi.script.src = this.gapi.src;
        this.gapi.accountscript = document.createElement('script');
        this.gapi.accountscript.src = "https://accounts.google.com/gsi/client";
        this.maxFileSize = 5 * (1024 * 1024 * 1024);
    }

    log = (c) => {
        if(this.debug){

            console.log(c);
            console.trace();
        }
    }

    FileType = (file, event) => {
        let data;
        switch(file.mimeType){
            case 'application/vnd.google-apps.folder':
                data = <div id={file.id} className="fileLink" onDoubleClick={event} data-name={file.name} drive-id={file.id}><div><i data-json={JSON.stringify(file)} data-name={file.name} data-id={file.id} class="fa-solid display-1 fa-folder"></i></div></div>
            break;
            case 'video/mp4':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><div>
                    {
                    file.thumbnailLink !== undefined ? 
                    <><img alt={file.name} referrerPolicy="no-referrer" data-json={JSON.stringify(file)} src={`https://lh3.google.com/u/0/d/${file.id}=w200-h190-p-k-nu-iv1`} /></>:
                    <i class="fa-solid display-1 fa-file-video" data-json={JSON.stringify(file)}></i>
                    }
                    
                </div> </div></a></div>
            break;
            case 'video/vnd.dlna.mpeg-tts':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i class="fa-solid display-1 fa-file-video"></i></div></a></div>
            break;
            case 'video/x-matroska':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i class="fa-solid display-1 fa-file-video"></i></div></a></div>
            break;
            case 'image/jpeg':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} href={file.webContentLink.replace('=download',"=view")}><div>
                    {
                    file.thumbnailLink !== undefined ? 
                    <><img referrerPolicy="no-referrer" data-json={JSON.stringify(file)} src={`https://lh3.google.com/u/0/d/${file.id}=w200-h190-p-k-nu-iv1`} /></>:
                    <i class="fa-solid fa-image"></i>
                    }
                </div></a></div>
            break;
            case 'image/png':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} href={file.webContentLink.replace('=download',"=view")}><div>
                {
                file.thumbnailLink !== undefined ? 
                <><img referrerPolicy="no-referrer" data-json={JSON.stringify(file)} src={`https://lh3.google.com/u/0/d/${file.id}=w200-h190-p-k-nu-iv1`} /></>:
                <i class="fa-solid fa-image"></i>
                }    
                </div></a></div>
            break;
            case 'image/svg+xml':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} href={file.webContentLink.replace('=download',"=view")}><div>
                {
                file.thumbnailLink !== undefined ? 
                <><img referrerPolicy="no-referrer" data-json={JSON.stringify(file)} src={`https://lh3.google.com/u/0/d/${file.id}=w200-h190-p-k-nu-iv1`} /></>:
                <i class="fa-solid fa-image"></i>
                }    
                </div></a></div>
            break;
            case 'audio/mpeg':
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} data-type="iframe" href={file.webContentLink.replace('=download',"=view")}><div><i style={{color:'#7B2869'}} data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-audio"></i></div></a></div>
            break;
            case "audio/wav":
                data = <div className="fileLink"><a data-fancybox data-caption={file.name} data-type="iframe" href={file.webContentLink.replace('=download',"=view")}><div><i style={{color:'rgb(217 48 37)'}} data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-audio"></i></div></a></div>
            break;
            case 'application/x-zip-compressed':
                data = <div className="fileLink" id={file.id} data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-zipper"></i></div></a></div>
            break;
            
            case 'application/zip':
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-zipper"></i></div></a></div>
            break;
            case 'application/pdf':
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-pdf text-danger"></i></div></a></div>
            break;
            case ('text/csv'):
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file-csv"></i></div></a></div>
            break;
            case ('application/vnd.google-apps.spreadsheet'):
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-regular display-1 fa-file-excel"></i></div></a></div>
            break;
            case ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'):
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-regular display-1 fa-file-excel"></i></div></a></div>
            break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-regular display-1 fa-file-word"></i></div></a></div>
            break;
            case 'application/vnd.google-apps.document':
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i data-json={JSON.stringify(file)} class="fa-regular display-1 fa-file-word"></i></div></a></div>
            break;
            default:
                
                data = <div id={file.id} className="fileLink" data-id={file.id}><a data-fancybox data-caption={file.name} data-type="iframe" href={`https://drive.google.com/file/d/${file.id}/preview`}><div><i  data-json={JSON.stringify(file)} class="fa-solid display-1 fa-file"></i></div></a></div>
            break;
        }
        return data;
    }
    
}

export default global;